// Powerbi
import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useEmbedDetails } from './EmbedDetailsProvider';
import Button from '../components/Buttons/updateButton';
import Simplemodal from '../components/Simplemodal';
import UrlProvider from "../Api/UrlProvider";

const Powerbi = ({id_u}) => {
  const { embedDetails } = useEmbedDetails();
  const reportContainerRef = useRef(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [lastSyncMessage, setLastSyncMessage] = useState('');

  useEffect(() => {
    // Mostrar el modal solo la primera vez que el componente se monta
    const fetchLastSyncTime = async () => {
      try {
        const response = await fetch(`${UrlProvider.defaults.baseURL}/last-sync-time`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        if (data.last_sync_time) {
          const lastSyncDate = new Date(data.last_sync_time);
  
          // Ajuste de zona horaria: restar 5 horas
          lastSyncDate.setHours(lastSyncDate.getHours() - 5);
  
          const formattedDate = lastSyncDate.toLocaleString('es-ES', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          });
  
          setLastSyncMessage(`Última actualización de datos:<br />${formattedDate}.`);
        } else {
          setLastSyncMessage(data.message);
        }
      } catch (error) {
        console.error('Error al obtener la última sincronización:', error);
        setLastSyncMessage('Error al obtener la última sincronización.');
      } finally {
        setModalOpen(true); // Abre el modal al montar el componente
      }
    };

    fetchLastSyncTime(); // Llama a la función para obtener la última sincronización

    // Limpiar la función cuando el componente se desmonta
    return () => {
      setModalOpen(false);
    };
  }, []); // Solo se ejecuta en la primera carga del componente

  useEffect(() => {
    const removeBordersAndBars = () => {
      const iframe = reportContainerRef.current.querySelector('iframe');
      if (iframe) {
        iframe.style.border = 'none';

        const coverDiv = document.createElement('div');
        coverDiv.style.position = 'absolute';
        coverDiv.style.bottom = '0';
        coverDiv.style.right = '0';
        coverDiv.style.width = '12%';
        coverDiv.style.height = '40px';
        coverDiv.style.backgroundColor = 'transparent';
        coverDiv.style.display = 'flex';
        coverDiv.style.alignItems = 'center';
        coverDiv.style.justifyContent = 'center';
        coverDiv.style.zIndex = 5;
        reportContainerRef.current.style.position = 'relative';

        const buttonContainer = document.createElement('div');
        buttonContainer.style.width = '100%';
        buttonContainer.style.height = '100%';
        buttonContainer.style.display = 'flex';
        buttonContainer.style.alignItems = 'center';
        buttonContainer.style.justifyContent = 'flex-end';
        buttonContainer.style.marginRight = '1%';

        coverDiv.appendChild(buttonContainer);
        reportContainerRef.current.appendChild(coverDiv);

        ReactDOM.render(<Button />, buttonContainer);
      }
    };

    removeBordersAndBars();

    return () => {
      // Cleanup function
    };
  }, []);

  return (
    <div ref={reportContainerRef} className="GraficaP" style={{ position: 'relative' }}>
      
      <PowerBIEmbed
        embedConfig={{
          type: 'report',
          id: id_u,
          embedUrl: embedDetails.embedUrl,
          accessToken: embedDetails.embedToken,
          tokenType: models.TokenType.Aad,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false
              }
            },
            background: models.BackgroundType.Transparent,
          }
        }}
        eventHandlers={
          new Map([
            ['loaded', function () {
              console.log('Report loaded');
              window.postMessage('powerbi_report_loaded', '*');
            }],
            ['rendered', function () { console.log('Report rendered'); }],
            ['error', function (event) { console.log(event.detail); }],
            ['visualClicked', () => console.log('visual clicked')],
            ['pageChanged', (event) => console.log(event)],
          ])
        }
        cssClassName={"Tablero"}
        getEmbeddedComponent={(embeddedReport) => {
          window.report = embeddedReport;
        }}
      />
      <Simplemodal
        isOpen={isModalOpen}
        message={lastSyncMessage}
        onClose={() => setModalOpen(false)}
      />
    </div>
  );
};

export default Powerbi;
/*<EmbedInfo/>
import EmbedInfo from './setData';
*/