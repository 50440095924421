import React, { useState } from 'react';
import { IoMdDownload } from "react-icons/io";
import { LuRefreshCw } from "react-icons/lu";
import UrlProvider from "../../Api/UrlProvider";
// Componente de modal para mostrar mensajes
const Modal = ({ message, onClose, isLoading }) => {
  return (
    <div style={modalStyles.overlay}>
      <div style={modalStyles.modal}>
        <p style={modalStyles.body}>{message}</p>

        <div style={{ height:"auto", width:"100%", display:"flex", alignContent:"center", justifyContent:"center"}}>
            <div className="navbar-container"
            style={{ minWidth: '80px',maxWidth:'80px'}}>
                <button className="navbar-btn" style={{padding:"4%"}}  onClick={onClose} disabled={isLoading}>
                    <div className='text1'><h3 style={{color: isLoading ? '#d4d3d3' : 'white'}}>Cerrar</h3></div>
                </button>
            </div>
        </div>
      </div>
    </div>
  );
};

// Estilos para el modal
const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',

  },
  body: {
    fontSize: 'clamp(16px,1.3vw,18px)',
    color: '#595959',
    fontWeight: '600',
    marginBottom: '20px',
    lineHeight: '1.5',
  },
};

const Button = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleSyncData = async () => {
    setIsLoading(true); // Iniciar carga
    setMessage('Se están actualizando los datos...');
    setShowModal(true); // Mostrar el modal

    try {
      const response = await fetch(`${UrlProvider.defaults.baseURL}/sync-data`, { method: 'POST' });

      if (!response.ok) {
        throw new Error('Error en la respuesta del servidor');
      }

      const data = await response.json();
      setMessage(data.message); // Actualizar mensaje con la respuesta del servidor
    } catch (error) {
      console.error('Error syncing data:', error);
      setMessage('Error al sincronizar los datos'); // Mostrar mensaje de error
    } finally {
      setIsLoading(false); // Finalizar carga
      // La recarga de la página puede ocurrir después de cerrar el modal
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    window.location.reload(); // Recargar página después de cerrar el modal
  };

  return (
    <div className="navbar-container2"
        style={{ minWidth: '105px',maxWidth:'105px', minHeight:"20px"}}
        >
          <button className="navbar-btn2"  onClick={handleSyncData} disabled={isLoading}>
          <LuRefreshCw
                style=
                {{ margin: "0% 5% 0% 5%",
                    width: "13%",
                    height: "auto",
                    color:"white"}} />
                <div className='text'><h2>Refrescar datos</h2></div>
          </button>
          {showModal && <Modal message={message} onClose={handleCloseModal} isLoading={isLoading} />}
        </div>

  );
};

export default Button;
