import ConstantsComponent from "../../../claims/Constants.jsx";
import ConversationsTable from '../../Boards/Explorer/ConversationsTable.jsx';
import ConversationsTable2 from '../../Boards/Explorer/ConversationsTable2.jsx';
import UrlProvider from "../../../Api/UrlProvider.jsx";
const Board_ExplorerUser= ()=>{
//1 comercial
//2 ing
        const {PROGRAM_ID} = ConstantsComponent();
        const program_id=`${UrlProvider.defaults.baseURL}/historial/${PROGRAM_ID}`
        //comercial
        const Url1=`${UrlProvider.defaults.baseURL}/historial/1`
        //ing
        const Url2=`${UrlProvider.defaults.baseURL}/historial/2`


        const mostrarBoarding = !isNaN(PROGRAM_ID) && parseInt(PROGRAM_ID, 10) === 2;
        const mostrarBoardcomer = !isNaN(PROGRAM_ID) && parseInt(PROGRAM_ID, 10) === 1;
        const mostrarBoardadmin = !isNaN(PROGRAM_ID) && parseInt(PROGRAM_ID, 10) === 3;

    return (
            <div className="TabExplorerCliente_User">
                {mostrarBoarding && <ConversationsTable program_id= {program_id}/>}
                {mostrarBoardcomer && <ConversationsTable program_id= {program_id}/>}
                {mostrarBoardadmin && <ConversationsTable2 url1={Url1} url2= {Url2}/>}

            </div>
        
    );
};
export default Board_ExplorerUser;
/*<TabHistoMsg id={ID} Url={Url} /> */