
import React from 'react';

/*Main boards and role clasification*/
import ConstantsComponent from '../claims/Constants.jsx';
import Powerbi from '../components/PowerBi';


/*
4 - ingenieria
5 - comercial
1 - admin
*/
const Statistics = () => {
  /*Retrieve the role type from the ConstantsComponent */
  const { TYPE_ID} = ConstantsComponent();
  const mostrarBoarding = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 4;
  const mostrarBoardcomer = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 5;
  const mostrarBoardadmin = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 1;
  /*Determine which board to display based on the role type ID 
  
  const mostrarBoardUser = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 2;
  */
  
  /*Conditionally render the appropriate board based on the role type */
  return (
    <div className='container_graph'>
      {mostrarBoarding && <Powerbi id_u='f51b9bd9-16d2-48a1-92f1-08993fc5dc1d'/>}
      {mostrarBoardcomer && <Powerbi id_u='5023b0e1-ab62-4892-89f4-4cf01c4bd331'/>}
      {mostrarBoardadmin && <Powerbi id_u='dd75659c-d7ed-4b4e-8e6e-1904cc570e72'/>}
    </div>
  );
};

export default Statistics;
