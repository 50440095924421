import React from 'react';

/*Main boards and role clasification*/
import Board_ExplorerClient from './Boards/Explorer/Board_ExplorerClient';

function Explorer() {
  
  return (
    /*Conditionally render the appropriate board based on the role type*/
    <div className='container_graph'>
      <Board_ExplorerClient />
    </div>
  );
}

export default Explorer;
