import React, { useState, useEffect, useRef } from 'react';
import IconoBot from "../../../utils/IconoBot";
import IconoUser from "../../../utils/IconoUser";
import axios from 'axios';
import ExportToExcel from '../../ExportToExcel';
import { MdFileUpload } from "react-icons/md";

const groupBySessionId = (data) => {
  const sortedData = data.sort((a, b) => new Date(a.Fecha) - new Date(b.Fecha));
  const grouped = sortedData.reduce((acc, item) => {
    if (!acc[item.sessionid]) {
      acc[item.sessionid] = [];
    }
    acc[item.sessionid].push(item);
    return acc;
  }, {});
  return grouped;
};

const ConversationsTable2 = ({ url1, url2 }) => {
  const timeZone = "America/Bogota";
  // Estado para almacenar los datos del endpoint
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedUrl, setSelectedUrl] = useState(url1);  // Estado para la URL seleccionada
  const [subtitle, setSubtitle] = useState("Chat comercial");


  const columnStyle = {
    display: 'block',
    maxHeight: '80px',
    overflowY: 'auto',
    whiteSpace: 'pre-wrap',
    paddingTop: '11px',
    color: "#687079",
    fontWeight: "400"
  };

  // Petición al endpoint usando useEffect
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Establecer estado de carga a true al cambiar de URL
        const response = await axios.get(selectedUrl);
        const fetchedData = response.data;

        if (fetchedData.length > 0) {
          // Ordenar los datos por fecha
          const sortedData = fetchedData.sort((a, b) => new Date(a.Fecha) - new Date(b.Fecha));

          // Tomar la primera y última fecha
          const earliestDate = new Date(sortedData[sortedData.length - 1].Fecha);
          const latestDate = new Date(sortedData[sortedData.length - 1].Fecha);

          // Establecer las fechas por defecto
          const defaultStartDate = earliestDate.toISOString().split('T')[0];
          const defaultEndDate = latestDate.toISOString().split('T')[0];

          setStartDate(defaultStartDate);
          setEndDate(defaultEndDate);
      }

      setData(fetchedData);
      setLoading(false);

      } catch (error) {
        console.error('Error al obtener los datos', error);
        setError('Error al obtener los datos');
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedUrl]); // Ejecutar cuando se cambia la URL seleccionada

  // Estado para almacenar los datos agrupados por sessionid
  const groupedData = groupBySessionId(data);

  // Filtrar los datos basados en las fechas seleccionadas
  const filteredData = Object.keys(groupedData).reduce((acc, sessionid) => {
    const filteredMessages = groupedData[sessionid].filter(message => {
      const messageDate = new Date(message.Fecha);
      const start = startDate ? new Date(startDate) : null;
      let end = endDate ? new Date(endDate) : null;

      if (end) {
        end.setDate(end.getDate() + 1);
      }

      if (start && end) {
        return messageDate >= start && messageDate < end;
      } else if (start) {
        return messageDate >= start;
      } else if (end) {
        return messageDate < end;
      }
      return true;
    });

    if (filteredMessages.length > 0) {
      acc[sessionid] = filteredMessages;
    }
    return acc;
  }, {});

  // Manejando el estado de carga y errores
  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;

  // Enumerar las sesiones en orden cronológico
  let sessionCounter = 1;
  function cleanMessage(message) {
    // Expresión regular ajustada para eliminar el formato 【X:Y†source】
    return message.replace(/【[^】]*】/g, '');
  }

  return (
    <div className="TabHistoriMensajesU">
      <div className="titulo2">
        <h2>Historial de Chats</h2>
        <h5>{subtitle}</h5>
      </div>
      <div className="container_graph">
        <div className="filtro">
            <div className='first'>
              <div className='container-input'>
                <txt>Fecha inicio</txt>
                <input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  placeholder="Fecha inicial"
                />
              </div>
              <div className='container-input'>
                <txt>Fecha final</txt>
                <input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}  // Almacenar la fecha final sin modificar
                  placeholder="Fecha final"
                />
              </div>
            </div>
            <div className="second">
                <div className="navbar-container2" style={{ minWidth: '100px',maxWidth:'100px', minHeight:"30px"}}>
                  <button
                    className="navbar-btn2"
                    onClick={() => {
                      setSelectedUrl(url1);
                      setSubtitle("Chat comercial");  // Cambiar subtítulo
                    }}
                  >
                  <MdFileUpload
                    style=
                    {{ margin: "0% 5% 0% 10%",
                        width: "15%",
                        height: "auto",
                        color:"white"}} />
                    <div className='text'><h2>Comercial</h2></div>
                  </button>
                </div>
                <div className="navbar-container2" style={{ minWidth: '100px',maxWidth:'100px', minHeight:"30px"}}>
                  <button className="navbar-btn2" onClick={() => {
                      setSelectedUrl(url2);
                      setSubtitle("Chat ingeniería");  // Cambiar subtítulo
                    }}
                  >
                  <MdFileUpload
                    style=
                    {{ margin: "0% 5% 0% 10%",
                        width: "15%",
                        height: "auto",
                        color:"white"}} />
                  <div className='text'><h2>Ingeniería</h2></div>
                  </button>
                </div>
                  
            </div>
        </div>
        <div className="table-containerT" >
          <table>
            <colgroup>
              <col style={{ width: "10%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "15%" }} />
            </colgroup>
            <thead>
              <tr style={{ fontSize: "clamp(12px, 1vw, 12px)" }}>
                <th>Chat</th>
                <th>Fecha</th>
                <th>Mensaje de</th>
                <th>Contenido</th>
                <th>Feedback (FB)</th>
                <th>Comentario FB</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(filteredData).map((sessionid) => {
                const sessionMessages = filteredData[sessionid];
                const currentSession = sessionCounter++;
                return sessionMessages.map((message, index) => (
                  <tr key={message.id}>
                    {index === 0 ? (
                      <td style={{ color: "#2B306E", fontWeight: "700" }} rowSpan={sessionMessages.length}>
                        Chat #{currentSession}
                      </td>
                    ) : null}
                    <td style={{ textAlign: "left", color: "#595959", fontWeight: "700" }}>
                    {(() => {
                          const messageDate = new Date(message.Fecha);
                          messageDate.setHours(messageDate.getHours() + 5);  // Sumamos 5 horas
                          return messageDate.toLocaleString("es-CO", {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: true  // Formato 24 horas
                          });
                        })()}
                    </td>
                    <td>
                      {message.type === 'user_message' ? (
                        <div className="content-center">
                          <IconoUser className="iconoUserSmall" />
                          <span style={{ fontWeight: 'bold', color: '#FF00B1' }}>Usuario</span>
                        </div>
                      ) : (
                        <div className="content-center">
                          <IconoBot className="iconoUserSmall" />
                          <span style={{ fontWeight: 'bold', color: '#2B306E' }}>Bot</span>
                        </div>
                      )}
                    </td>
                    <td style={columnStyle} dangerouslySetInnerHTML={{  __html: cleanMessage(message.content)
                     .replace(/<table/g, '<table style="border: 1px solid #595959; width: 100%; border-collapse: collapse;font-size: 80%;"')
                     .replace(/<thead/g, '<thead style="position: relative; background-color: white;"')
                     .replace(/<th/g, '<th style="border: 1px solid #595959; padding: 8px; text-align: left; background-color: white; position: relative"')
                     .replace(/<td/g, '<td style="border: 1px solid #595959; padding: 8px; text-align: left;"')
                    }} />
                    <td style={{ color: "#595959", fontWeight: "700" }}>{message.type !== 'user_message' ? message.Feedback : ''}</td>
                    <td style={columnStyle}>{message.type !== 'user_message' ? message.Comentario_Feedback : ''}</td>
                  </tr>
                ));
              })}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <ExportToExcel Url={selectedUrl} name="Historial_chatbot.xlsx" />
        </div>
      </div>
    </div>
  );
};

export default ConversationsTable2;
