import axios from 'axios';

const UrlProvider = axios.create({
//Url of deploy API https://flaskapimentor.azurewebsites.net
new_intent: 'https://streamingcome.azurewebsites.net',
//new testing pricing intent 
baseURL: 'https://beststreamnewplan.azurewebsites.net',
//Url of local testing API
flaskURL:'http://127.0.0.1:8000'
});

export default UrlProvider;